<template>
	<!-- <nav>
		<router-link to="/">Home</router-link> |
		<router-link to="/about">About</router-link>
	</nav> -->
	<router-view />
</template>

<style lang="scss">
* {
	font-family: Arial, Helvetica, sans-serif;
	box-sizing: border-box;
}

body {
	background-color: #fff;
	// margin: 8px;
}
</style>
